import React from "react";
import Home from "./Home"; // Import the new image
// import Memes from "./Memes"; // Import the new image
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";

const App = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home/>} />
      {/* <Route path="/memes" element={<Memes/>} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
