import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFireworksPreset } from "@tsparticles/preset-fireworks";

const Fireworks = () => {
    
  const [init, setInit] = useState(false);
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFireworksPreset(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    
    console.log(container);
  };

  const options = useMemo(
    () => ({particles: {
      shape: {
        type: "square",
      },
    },
    preset: "fireworks",
  }),[],);

  if (init) {
    return (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}/>
    );
}

return <></>;
};
export default Fireworks;