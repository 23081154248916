import React from "react";
import ParticlesStars from "./ParticlesStars"; // Assuming this is the ts-particles component
import Logo from './logo.png';
import { TypeAnimation } from "react-type-animation";
import ReactPlayer from 'react-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import "./App.css";

const Home = () => {
  return (
    <div style={styles.container} className="App">
      <div style={styles.stripes}></div>
      <div className="content">
      <ParticlesStars style={styles.particles} />
        <img src={Logo} className="logo-main" alt="Logo" />
        <div style={styles.textContainer}>
          <h1 style={styles.title}>PRESIDENTIAL DROPOUT</h1>
          <p style={styles.date}>JULY 21</p>
          <TypeAnimation
            sequence={[
              "BODEN DROPPED OUT!",
              1000,
              "TO START HIS RAPPER CAREER",
              1000,
              "DON'T MISS",
              1000,
              "THE FIRST ALBUM",
              1000,
              "PRESIDENTIAL DROPOUT",
              1000,
              "MURICA",
              1000,
            ]}
            wrapper="span"
            speed={50}
            style={styles.tagline}
            repeat={Infinity}
          />
          <div style={styles.socialLinks}>
            <a
              href="https://t.me/PresidentialDropOut"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.socialButton}
              className="socialButton"
            >
              <i className="fab fa-telegram-plane"></i> Telegram
            </a>
            <a
              href="https://x.com/PresDropout"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.socialButton}
              className="socialButton"
            >
              <i className="fab fa-twitter"></i> Twitter
            </a>
            <a
              href="https://dexscreener.com/solana/az6uaichjwjmnesynkumcacwzrmnhrxzmv9mcdrhixpz"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.socialButton}
              className="socialButton"
            >
              <i className="fa fa-moon-o"></i> Moonshot
            </a>
          </div>
        </div>
      </div>
      <div style={styles.stripes}></div>
      <div className="song">
      <AudioPlayer
    autoPlay
    src="drugdealing.mp3"
    // onPlay={e => console.log("onPlay")}
  />
        </div>
      <div className="grid-video">
      <ReactPlayer 
        url='video.mp4' 
        controls={true} 
        style={{ marginTop: '30px', marginLeft: 'auto', marginBottom: '20px', marginRight: 'auto', paddingBottom: '20px', maxWidth: '450px',
        zIndex: 1,
        margin: 'auto',
        position: "relative",
      
      }} 
      />
      <ReactPlayer 
        url='video2.mp4' 
        controls={true} 
        style={{ marginTop: '30px', marginLeft: 'auto', marginBottom: '20px', marginRight: 'auto', paddingBottom: '20px', maxWidth: '450px',
        zIndex: 1,
        margin: 'auto',
        position: "relative",
      
      }} 
      />
      <ReactPlayer 
        url='video3.mp4' 
        controls={true} 
        style={{ marginTop: '30px', marginLeft: 'auto', marginBottom: '20px', marginRight: 'auto', paddingBottom: '20px', maxWidth: '450px',
        zIndex: 1,
        margin: 'auto',
        position: "relative",
      
      }} 
      />
      <ReactPlayer 
        url='video4.mp4' 
        controls={true} 
        style={{ marginTop: '30px', marginLeft: 'auto', marginBottom: '20px', marginRight: 'auto', paddingBottom: '20px', maxWidth: '450px',
        zIndex: 1,
        margin: 'auto',
        position: "relative",
      
      }} 
      />
      <ReactPlayer 
        url='video5.mp4' 
        controls={true} 
        style={{ marginTop: '30px', marginLeft: 'auto', marginBottom: '20px', marginRight: 'auto', paddingBottom: '20px', maxWidth: '450px',
        zIndex: 1,
        margin: 'auto',
        position: "relative",
      
      }} 
      />
      <ReactPlayer 
        url='video6.mp4' 
        controls={true} 
        style={{ marginTop: '30px', marginLeft: 'auto', marginBottom: '20px', marginRight: 'auto', paddingBottom: '20px', maxWidth: '450px',
        zIndex: 1,
        margin: 'auto',
        position: "relative",
      
      }} 
      />
      <ReactPlayer 
        url='video7.mp4' 
        controls={true} 
        style={{ marginTop: '30px', marginLeft: 'auto', marginBottom: '20px', marginRight: 'auto', paddingBottom: '20px', maxWidth: '450px',
        zIndex: 1,
        margin: 'auto',
        position: "relative",
      
      }} 
      />
      <ReactPlayer 
        url='video8.mp4' 
        controls={true} 
        style={{ marginTop: '30px', marginLeft: 'auto', marginBottom: '20px', marginRight: 'auto', paddingBottom: '20px', maxWidth: '450px',
        zIndex: 1,
        margin: 'auto',
        position: "relative",
      
      }} 
      />
      </div>
      <div style={styles.disclaimer}>
        $PresidentialDropout coin has no association with anyone. 
        $PresidentialDropout is a meme coin with no intrinsic value or 
        expectation of financial return. There is no formal team or roadmap. 
        The coin is completely useless and for entertainment purposes only.
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    backgroundColor: "#282c34",
    color: "white",
    padding: "20px",
    position: "relative",
    height: "100vh",
    width: "100vw",
    overflow: "scroll",
  },
  particles: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    position: "relative",
  },
  textContainer: {
    maxWidth: "600px",
    textAlign: "center",
    zIndex: 1,
  },
  title: {
    fontSize: "6em",
    margin: "0",
    color: "#FFFFFF",
    textShadow: "0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 20px #FF0000, 0 0 30px #FF0000, 0 0 40px #FF0000, 0 0 55px #FF0000, 0 0 75px #FF0000",
  },
  date: {
    fontSize: "6em",
    margin: "0",
    color: "#FFFFFF",
    textShadow: "0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 20px #0000FF, 0 0 30px #0000FF, 0 0 40px #0000FF, 0 0 55px #0000FF, 0 0 75px #0000FF",
  },
  tagline: {
    fontSize: "3em",
    margin: "0",
    color: "#FFFFFF",
  },
  socialLinks: {
    position: "relative",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
  },
  socialButton: {
    color: "white",
    margin: "20px",
    textDecoration: "none",
    fontSize: "1.5em",
    transition: "transform 0.2s, opacity 0.2s",
    display: "inline-block",
  },
  logoMain: {
    width: "80%",
    height: "auto",
    maxWidth: "600px",
    maxHeight: "600px",
    marginBottom: "50px",
  },
  disclaimer: {
    textAlign: 'center',
    maxWidth: '600px',
    fontSize: '12px',
    color: "#fff",
    zIndex: 1,
    margin: 'auto',
    position: "relative",
  },
  "@keyframes scaleOpacity": {
    "0%": { transform: "scale(1)", opacity: 1 },
    "50%": { transform: "scale(1.2)", opacity: 0.7 },
    "100%": { transform: "scale(1)", opacity: 1 },
  },
  socialButtonHover: {
    animation: "scaleOpacity 1s infinite",
  },
  "@media (min-width: 1024px)": {
    content: {
      flexDirection: "row",
    },
    textContainer: {
      textAlign: "left",
      marginLeft: "20px",
    },
  },
  stripes: {
    width: '100%',
    position: 'relative',
    height: '30px', // Adjust the height as needed
    background: 'linear-gradient(to right, red 33.33%, white 33.33%, white 66.66%, blue 66.66%)',
    paddingTop: '10px',
    paddingBottom: '10px',
    zIndex: 50
  },
};

export default Home;
